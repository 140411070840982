import React, {useRef, useEffect}from 'react'
import {gsap, Elastic} from "gsap";
import { NavLink} from 'react-router-dom'
import '../Css/NavBar.css'
import menu from '../Asset/menu.svg'
import {BiLogoLinkedin, BiLogoGithub, BiLogoBehance, BiLogoTwitter} from 'react-icons/bi'
import {MdClose} from 'react-icons/md'


const NavBar = () => {
  const navRef = useRef(null);
  const animatedNavWebRef = useRef(null);
  const animatedNavMobileRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
        [animatedNavWebRef.current],
        {yPercent:-50, opacity:0},
        {yPercent:0, opacity:1, ease:Elastic, duration:0.5}
    )
    gsap.fromTo(
        [animatedNavMobileRef.current],
        {opacity:0},
        {opacity:1, ease:Elastic, duration:0.5}
    )
  }, [])



  const showNavbar = () => {
    if (navRef.current) {
        navRef.current.classList.toggle('responsive_nav');
      }
  }

  return (
    <div className='navbar' >
      
    <div className='navbar-web' ref={animatedNavWebRef}>
        <section className='left'>
            <NavLink className="nav-link" to='/'>work</NavLink>
            <NavLink className="nav-link" to='/more'>more projects</NavLink>
            <NavLink className="nav-link" to='/aboutandcontact'>about & contact</NavLink>
        </section>

        <section className='center'>
            <NavLink className="nav-link-center" to='/'>Tundé Adekoya</NavLink>
        </section>

        <section className='right'>
            <NavLink className="nav-link icon" to='https://twitter.com/tunde__dev/'><BiLogoTwitter/></NavLink>
            <NavLink className="nav-link icon" to='https://www.behance.net/AdekoyaTunde/'><BiLogoBehance/></NavLink>
            <NavLink className="nav-link icon" to='https://github.com/TundeAdekoya/'><BiLogoGithub/></NavLink>
            <NavLink className="nav-link icon" to='https://www.linkedin.com/in/adekoya-tunde/'><BiLogoLinkedin/></NavLink>
        </section>
      </div>


      <section className="cta-mobile navbar-mobile" ref={animatedNavMobileRef}>
                <div className="nav-icons">
                    <NavLink className="nav-link-center" to='/'>Tundé Adekoya</NavLink>
                </div>

            <div className="nav-mobile" ref={navRef}>
                <NavLink className='nav-link-mobile'  to='/' onClick={showNavbar} >work</NavLink>
                <NavLink className='nav-link-mobile' to='/more' onClick={showNavbar} >more projects</NavLink>
                <NavLink className='nav-link-mobile' to='/aboutandcontact'  onClick={showNavbar} >about & contact</NavLink>
                <NavLink className='nav-link-mobile' to='https://www.linkedin.com/in/adekoya-tunde/'  onClick={showNavbar} >linkedin</NavLink>
                <NavLink className='nav-link-mobile' to='https://www.behance.net/AdekoyaTunde/' onClick={showNavbar} >behance</NavLink>
                <NavLink className='nav-link-mobile' to='https://github.com/TundeAdekoya/' onClick={showNavbar} >github</NavLink>
                <NavLink className='nav-link-mobile'  to='https://twitter.com/tunde__dev/'  onClick={showNavbar} >twitter</NavLink>
                <div className= 'mobile-menu-header nav-icons nav-close-btn' onClick={showNavbar}>
                    <NavLink className="nav-link-center" to='/'>Tundé Adekoya</NavLink>
                    <MdClose className='nav-bar-logo' />
                </div>  
            </div>

            <div className="nav-icons " onClick={showNavbar}>
                <img src={menu} alt="" className='nav-bar-menu' />
            </div>
        </section>
    </div>
  )
}

export default NavBar