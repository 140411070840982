import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../Css/Pagenotfound.css'


const Pagenotfound = () => {
    const navigate = useNavigate()
    
  return (
    <div id='Pagenotfound'>
        <p>Page not found, head back to <span onClick={() => navigate('/')}  >home</span></p>
    </div>
  )
}

export default Pagenotfound