import React from 'react'
import '../Css/Footer.css'
import {BiCopyright} from 'react-icons/bi'
import {IoIosArrowUp} from 'react-icons/io'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const top = () => { window.scrollTo({top:0, behavior:'smooth'}) }

  return (
    <div className='footer'>
        <section className='center'>
            <div className='copyright'><BiCopyright/><p>{"  "}{currentYear}</p></div>
            <div className='author'>Designed and Developed by Tundé</div>
        </section>
        <section className='right' onClick={top}><IoIosArrowUp/></section>
    </div>
  )
}

export default Footer